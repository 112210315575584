<template>
  <b-container class="create-account-wizard-asset create-account-wizard-asset-step-02">
    <b-row no-gutters>
      <b-col sm="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 pl-lg-1 pr-lg-3" align-self="start">
        <h2>
          {{ $t(translation_path + 'title_name') }}
        </h2>
        <i18n :path="translation_path + 'intro_p1_main'" tag="p">
          <b-link slot="link" target="_blank" :to="'/reports/other/account-plan-v2/assets'">{{ $t('incomes_expenses.reports.account_plan_report') }}</b-link>
        </i18n>
      </b-col>
      <b-col sm="12" md="7" class="accounts_wrapper pt-0 pb-2 px-3 py-md-5 px-md-4 py-lg-5 pl-lg-5 pr-lg-3">
        <b-table ref="categoryTable" class="spirecta-simple-table accounts-table depth-1" show-empty hover responsive striped
                 :items="accounts"
                 :fields="fields"
                 selectable
                 select-mode="single"
                 @row-selected="rowSelected"
                 stacked="md"
          >
          <template v-slot:cell(title)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-link @click="$refs.categoryTable.selectRow(row.index)">{{ row.value | displayAccountTitle( row.item.code, currentCOA.prefix_account_title_with_code ) }}</b-link>
          </template>
          <template v-slot:head(actions)="">
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetAccountWizardStep02 from '@/views/assets-liabilities/accounts/wizard-steps/asset/AssetAccountWizardStep02'
export default {
  name: 'AssetAccountWizardStep02',
  extends: AssetAccountWizardStep02
}
</script>

<style lang="scss">
  .create-account-wizard-asset-step-02 {
    .accounts-table
    {
      thead tr th{
        background: white;
        padding:15px 0px 15px 10px;
        font-size: 125%;
      }
      thead tr th h2{
        margin:0;
      }
      tbody{
        tr {
          &:hover td{
            background-color: #36b373;
            color:white;
            a {
              color: #fff;
            }
          }
          &.b-table-row-selected
          {
            background: #36b373 !important;
            color:#fff;
            a {
              color: #fff;
            }
          }
        }
        td {
          padding: 10px 10px;
          border-top:1px dashed #CACACA;
          vertical-align: middle;
        }
        tr:nth-child(odd){
          background: #fefeff;
        }
        tr:nth-child(even){
          background: #fefeff;
        }
        // Add left padding to first column
        td:nth-child(1){
          padding-left:20px;
        }
        // Make important column bold
        td:nth-child(1){
          font-weight:700;
        }
        button {
          &.active{
            border: 2px solid #0065ff;
          }
        }
      }
    }
  }
</style>
