<template>
  <b-container class="create-account-wizard-asset create-account-wizard-asset-step-01">
    <b-row no-gutters>
      <b-col sm="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 pl-lg-1 pr-lg-3" align-self="center">
        <h2>
          {{ $t(translation_path + 'title_name') }}
        </h2>
        <i18n :path="translation_path + 'intro_p1_main'" tag="p">
          <b-link slot="link" href="javascript:void(0);" :data-beacon-article-sidebar="helpLink">{{ $t(translation_path + 'read_more_link') }}</b-link>
        </i18n>
      </b-col>
      <b-col sm="12" md="7" class="accounts_wrapper pt-0 pb-2 px-3 py-md-5 px-md-4 py-lg-5 pl-lg-1 pr-lg-3">

        <b-form-group
          label-class="FormLabels pr-4 text-black"
          label-for="account_title"
          label-cols-lg="2"
          label-align-lg="right"
          required
          :invalid-feedback="invalidAccountTitleFeedback"
          :label="$t(translation_path + 'title_label')"
          >
            <template slot="description" v-if="typeOfAccount">{{ $t(translation_path + 'title_help_text_'+typeOfAccount) }}</template>
          <b-form-input id="account_title" class="rounded-pill"
                        :placeholder="$t(translation_path+'title_placeholder')"
                        :state="$v.account_title.$dirty ? !$v.account_title.$error : null"
                        v-model="account_title"
                        @input="$v.account_title.$touch()"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetAccountWizardStep01 from '@/views/assets-liabilities/accounts/wizard-steps/asset/AssetAccountWizardStep01'
export default {
  name: 'AssetAccountWizardStep01',
  extends: AssetAccountWizardStep01
}
</script>

<style lang="scss">

</style>
