<template>
  <b-container class="create-account-wizard-asset create-account-wizard-asset-step-03">
    <b-row no-gutters>
      <b-col sm="12" md="5" lg="4" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 pl-lg-1 pr-lg-3" align-self="start">
        <h2>
          {{ $t(translation_path + 'title_name') }}
        </h2>
        <i18n :path="translation_path + 'intro_p1_main'" tag="p">
          <b-link slot="link" href="javascript:void(0);" :data-beacon-article-sidebar="helpLink">{{ $t(translation_path + 'read_more_link') }}</b-link>
        </i18n>
      </b-col>
      <b-col sm="12" md="7" lg="8" class="accounts_wrapper pt-0 pb-2 px-3 py-md-5 px-md-4 py-lg-5 pl-lg-5 pr-lg-3">
        <template v-if="!isUpdate">
          <div class="starting_value">
            <h4 style="border-top:0">{{ $t(translation_path + 'starting_value')}}</h4>
            <p>
              {{ $t(translation_path + 'starting_value_description')}}
            </p>
            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              :label="$t(translation_path + 'starting_value_amount')"
            >
              <currency-input
                class="form-control text-left rounded-pill"
                v-model="amount"
                :locale="currentCOA.locale ? currentCOA.locale : 'sv-SE'"
                :currency="currentCOA.currency_iso ? currentCOA.currency_iso : 'SEK'"
              >
              </currency-input>
            </b-form-group>
            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              :label="$t(translation_path + 'starting_value_date')"
            >
              <datepicker :language="selectedLang" :format="dateFormatter" v-bind:input-class="{'form-control rounded-pill': true }" v-model="date" :monday-first="startWeekByMonday" :clear-button="true" clear-button-icon="fa fa-times"></datepicker>
              <template slot="description">{{ $t(translation_path + 'starting_value_date_description') }}</template>
            </b-form-group>
          </div>
        </template>
        <!--Advanced Toggle-->
        <b-form-group
          :label-class="'pr-4 text-black' + (currentLang === 'sv' ? ' pt-2 ' : ' pt-0 ')"
          label-cols-lg="4"
          label-align-lg="right"
          :label="$t(translation_path + 'toggle_advanced')"
        >
          <b-form-checkbox v-model="showOptions" name="toggle-button" size="lg" v-bind:class="currentLang === 'sv' ? 'pt-1' : 'pt-2'" switch>
          </b-form-checkbox>
        </b-form-group>
        <!--status-->
        <template v-if="showOptions === true">

          <h4>{{ $t(translation_path + 'import_account_history') }}</h4>
          <p>{{ $t(translation_path + 'import_account_history_description') }}</p>

          <b-form-group
            :label-class="$i18n.locale === 'sv' ? 'pt-1 pr-4 text-black' : 'pt-3 pr-4 text-black'"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="is_importable"
            :label="$t(translation_path + 'is_importable')"
            :state="importableState"
            :invalid-feedback="importableStateFeedback"
          >
            <b-form-select id="is_importable" v-model="is_importable" :options="importOptions" :state="importableState" class="rounded-pill"></b-form-select>
          </b-form-group>
          <template v-if="is_importable === 1">
            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="import_type"
              :state="importableValueState"
              :label="$t(translation_path + 'import_type')"
              :invalid-feedback="invalidImportableValueFeedback"
            >
              <b-form-select id="import_type" v-model="importableValue" :options="importableOptions" :state="importableValueState" class="rounded-pill"></b-form-select>
            </b-form-group>
            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="default_parser"
              :label="$t(translation_path + 'default_parser')"
            >
              <custom-select
                class="rounded-pill"
                v-model="importable_default_parser"
                :options="parserOptions"
                label="text"
                valueKey="value"
                :placeholder="$t(translation_path + 'errors.is_importable_required')"
              />
            </b-form-group>
          </template>

          <h4>{{ $t(translation_path + 'subtype_title') }}</h4>
          <p>{{ $t(translation_path + 'subtype_description') }}</p>

          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="subtype"
            :label="$t(translation_path + 'subtype')"
          >
            <b-form-select id="subtype" v-model="subtype" :options="subtypeOptions" class="rounded-pill"></b-form-select>
          </b-form-group>

          <h4>{{ $t(translation_path + 'compare_yourself_heading') }}</h4>
          <p>{{ $t(translation_path + 'compare_yourself_description') }}</p>
          <b-form-group
            label-class="pt-0 pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
          >
            <template slot="label">
              <i18n :path="translation_path + (currentUser.strict_accounting_mode ? 'global_account': 'global_category')">
                <b-link slot="help_link" href="javascript:void(0);" :data-beacon-article-sidebar="globaCategoryLink">(?)</b-link>
              </i18n>
            </template>
            <custom-select
              class="rounded-pill"
              v-model="global_id"
              :options="globalAccountsOptions"
              groupingValues="accounts"
              groupingLabel="label"
              :placeholder="currentUser.strict_accounting_mode ? $t(translation_path + 'select_global_account') : $t(translation_path + 'select_global_category')"
              :search-placeholder="$t('common.type_to_filter')"
              :noResultText="$t('common.no_option_found')"
              :loading="loadingGlobalAccounts"
              :sortGroupLabel="false"
              :enableDiselect="true"
              label="label"
            />
          </b-form-group>

          <h4>{{ $t(translation_path + 'four_buckets_principle') }}</h4>
          <p>{{ $t(translation_path + 'four_buckets_principle_description') }}</p>
          <b-form-group
            :label="$t(translation_path + 'in_bucket_label')"
            label-class="FormLabels pr-4 pt-1 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="in_bucket"
            :state="inbucketState"
            :invalid-feedback="inbucketStateFeedback"
          >
            <b-form-select id="in_bucket" v-model="in_bucket" :options="inbucketOptions" :state="inbucketState" class="rounded-pill"></b-form-select>
          </b-form-group>

          <template v-if="in_bucket">
            <b-form-group
              :label="$t(translation_path + 'is_bucket_free_capital')"
              label-class="FormLabels pr-4 pt-3 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="is_bucket_free_capital"
              :state="isBucketFreeCapitalState"
              :invalid-feedback="isBucketFreeCapitalStateFeedback"
            >
              <b-form-select id="in_bucket_free_capital" v-model="is_bucket_free_capital" :options="isBucketFreeCapitalOptions" :state="isBucketFreeCapitalState" class="rounded-pill"></b-form-select>
            </b-form-group>
          </template>

          <h4>{{ $t(translation_path + 'other_options') }}</h4>
          <p>{{ $t(translation_path + 'other_options_description') }}</p>

          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="status"
            :state="statusState"
            :invalid-feedback="invalidStatusFeedback"
            :label="$t(translation_path + 'status_label')"
          >
            <b-form-select id="status" v-model="status" :options="aStatusOptions" :state="statusState" @input="optionChangeHandler" class="rounded-pill"></b-form-select>
          </b-form-group>
          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="allow_update"
            :state="allowUpdateState"
            :invalid-feedback="allowUpdateStateFeedback"
          >
            <template slot="label">
              <i18n :path="translation_path + 'allow_update'">
                <b-link slot="help_link" href="javascript:void(0);" :data-beacon-article-sidebar="allowUpdateLink">(?)</b-link>
              </i18n>
            </template>
            <b-form-select id="allow_update" v-model="allow_update" :options="allowUpdateOptions" :state="allowUpdateState" class="rounded-pill"></b-form-select>
          </b-form-group>
          <!--offset account-->
          <template v-if="allow_update === 1">
            <b-form-group
              label-class="FormLabels pr-4 text-black pt-0"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="offset_account"
              :state="default_offset_account_id !== null ? true : null"
            >
              <template slot="label">
                {{ $t(translation_path + 'offset_account')}}
              </template>
              <b-form-select id="offset_account" v-model="default_offset_account_id" :state="default_offset_account_id !== null ? true : null" class="rounded-pill">
                <b-form-select-option :value="null">{{ $t('common.please_select_an_option')}}</b-form-select-option>
                <b-form-select-option-group v-for="(item, keys) in offsetOptions" :label="$te('common.' + item.name) ? $t('common.' + item.name) : item.name" :key="'accountGroup' + keys">
                  <b-form-select-option v-for="accItem in item.accounts" :value="accItem.id" :key="'accountOption' + accItem.id">{{ accItem.title | displayAccountTitle(accItem.code, currentCOA.prefix_account_title_with_code) }}</b-form-select-option>
                </b-form-select-option-group>
              </b-form-select>
            </b-form-group>
          </template>
          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            :invalid-feedback="invalidAccountCodeFeedback"
            :state="!$v.account_code.$error"
            label-for="identification_code"
          >
            <template slot="label">
              <i18n :path="translation_path + 'code_label'">
                <b-link slot="help_link" href="javascript:void(0);" :data-beacon-article-sidebar="helpCodeLink">(?)</b-link>
              </i18n>
            </template>
            <template slot="description">{{ $t(translation_path + 'code_help_text') }} {{ $t(translation_path + 'code_help_text_recommendation_'+typeOfAccount ) }}</template>
            <b-form-input id="identification_code" class="rounded-pill" :placeholder="$t(translation_path + 'code_placeholder')" v-model="account_code" :state="$v.account_code.$dirty ? !$v.account_code.$error : null" @input="$v.account_code.$touch()"></b-form-input>
          </b-form-group>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetAccountWizardStep03 from '@/views/assets-liabilities/accounts/wizard-steps/asset/AssetAccountWizardStep03'

export default {
  name: 'AssetAccountWizardStep03',
  extends: AssetAccountWizardStep03
}
</script>

<style lang="scss">
.create-account-wizard-asset-step-03 {
  .form-control[readonly]{
    background-color:#fff;
  }
  .FormLabels {
    padding-top: 16px;
  }
  .vdp-datepicker__clear-button {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  .starting_value {
    padding-bottom: 5px;
    border-bottom: 1px dashed gray;
    margin-bottom: 15px;
    h4 {
      margin-top: 0;
      padding-top: 10px;
    }
  }
  h4{margin-top:25px;padding-top:25px;border-top:1px dashed gray}
}
</style>
