<template>
  <b-container class="account-wizard bg-light page-wrapper">
    <b-row class="main-content-wrapper create-asset-wrapper-v2" no-gutters>
      <b-col>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        <h1 class="text-left mb-0" v-if="!isUpdate">{{ $t( translationPath + ('create_asset')) }}{{ h1AccountTitle }}</h1>
        <h1 class="text-left mb-0" v-if="isUpdate">{{ $t( translationPath + ('edit_asset')) }}{{ h1AccountTitle }}</h1>
        <div class="step-container-v2">
          <div class="stepper bg-light mx-auto text-regular">

            <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#e1a5a1'"  :errorColor="'#525670'" ref="step">
              <tab-content :title="$t(translationPath+ 'general_info')" :before-change="()=>validateStep('step1')">
                <asset-account-wizard-step01 ref="step1"  @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :isUpdate="isUpdate"></asset-account-wizard-step01>
              </tab-content>
              <tab-content :title="$t(translationPath+ 'asset_group')" :before-change="()=>validateStep('step2')">
                <asset-account-wizard-step02 ref="step2" @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :isUpdate="isUpdate"></asset-account-wizard-step02>
              </tab-content>
              <tab-content :title="$t(translationPath+ 'advanced_options')" :before-change="()=>validateStep('step3')">
                <asset-account-wizard-step03 ref="step3" @on-validate="mergePartialModels" :initialData="editAccountDetails" :typeOfAccount="typeOfAccount" :finalModel="finalModel" v-bind:totalRows="5" :isUpdate="isUpdate"></asset-account-wizard-step03>
              </tab-content>
              <template slot="footer" slot-scope="props">
                <div :class="{ hasSpace: props.activeTabIndex !== 0 }">
                  <div class=wizard-footer-left>
                    <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.previous') }}
                    </wizard-button>
                  </div>
                  <div class="line" v-if="props.activeTabIndex !== 0"></div>
                  <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="props.activeTabIndex === 1 && nextDisabled" tabindex="0">
                      {{ $t('common.next') }}
                    </wizard-button>
                    <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.save') }}
                    </wizard-button>
                  </div>
                </div>
              </template>
            </form-wizard>
            <success-modal v-if="!isUpdate"
                           :title="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'title_account' : 'title_category'),{account: this.finalModel.account_title})"
                           :message="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'message_account' : 'message_category'),{account: this.finalModel.account_title})"
                           :button="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'button_account_overview' : 'button_category_overview'),{account: this.finalModel.account_title})"
                           :redirect="'/reports/other/account-plan-v2/' + (typeOfAccount === 'asset' ? 'assets' : 'liabilities')"
                           :orLink="'/redirect?to=accountsAssetsCreate&accountType='+typeOfAccount"
                           :orLinkText="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'or_create_account' : 'or_create_category'))"
            ></success-modal>
            <success-modal v-if="isUpdate"
                           :title="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'title_account_update' : 'title_category_update'),{account: this.finalModel.account_title})"
                           :message="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'message_account_update' : 'message_category_update'),{account: this.finalModel.account_title})"
                           :button="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'button_account_overview_update' : 'button_category_overview_update'),{account: this.finalModel.account_title})"
                           :redirect="'/reports/performance/accounts/' + iAccountId + '/view'"
                           :orLink="'/reports/other/account-plan-v2/' + (typeOfAccount === 'asset' ? 'assets' : 'liabilities')"
                           :orLinkText="$t(translationPathModal + ( currentUser.strict_accounting_mode ? 'or_goto_account_overview' : 'or_goto_category_overview'))"
            ></success-modal>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssetAccountWizardStep01 from './wizard-steps/asset/AssetAccountWizardStep01'
import AssetAccountWizardStep02 from './wizard-steps/asset/AssetAccountWizardStep02'
import AssetAccountWizardStep03 from './wizard-steps/asset/AssetAccountWizardStep03'
import SuccessModal from '@/components/modals/SuccessModal'
import titleMixins from '@/mixins/title'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import axios from 'axios'
import EventBus from '@/event-bus'
import { mapState } from 'vuex'

export default {
  name: 'wizardAccountAsset',
  mixins: [titleMixins],
  data () {
    return {
      nextDisabled: false,
      finalModel: {},
      isCompleted: false,
      h1AccountTitle: null,
      isUpdate: false,
      editAccountDetails: {},
      translationPath: 'assets_liabilities.create_account_wizard.common.',
      translationPathModal: 'assets_liabilities.create_account_wizard.assets.success_modal.',
      isStep3Ok: null,
      typeOfAccount: 'asset',
      iAccountId: null
    }
  },
  components: {
    SuccessModal,
    FormWizard,
    TabContent,
    WizardButton,
    AssetAccountWizardStep01,
    AssetAccountWizardStep02,
    AssetAccountWizardStep03
  },
  created () {
    EventBus.$on('group_selected', status => {
      this.nextDisabled = !status
    })
    if (this.$route.name === 'asset-accounts-edit') {
      this.isUpdate = true
      if (this.isUpdate) {
        this.loadAccountDetails()
      }
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      const aBreadCrumb = [
        { text: this.$t('common.header.header_menu.assets-and-liabilities'), to: '/assets-liabilities/transactions' },
        { text: this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'accounts' : 'categories')), to: '/assets-liabilities/' + this.typeOfAccount },
        { text: this.$t(this.accountTypeTranslationString), to: '/assets-liabilities/' + this.typeOfAccount },
        { text: '', active: true }
      ]
      return aBreadCrumb
    },
    accountTypeTranslationString () {
      return (this.typeOfAccount) ? this.translationPath + this.typeOfAccount : this.translationPath + 'asset'
    },
    titleMeta () {
      if (this.isUpdate) {
        return this.$t(this.translationPath + 'edit_asset')
      }
      return this.$t(this.translationPath + 'create_asset')
    }
  },
  methods: {
    getFormattedDate (iDate) {
      const oDate = new Date(iDate)
      let sMonth = oDate.getMonth() + 1
      if (sMonth < 10) {
        sMonth = '0' + sMonth
      }
      let sDay = oDate.getDate()
      if (sDay < 10) {
        sDay = '0' + sDay
      }
      return oDate.getFullYear() + '-' + sMonth + '-' + sDay
    },
    validateStep (name) {
      const selectedAccountGroup = this.finalModel && Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && this.finalModel.selected_account && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'id') ? this.finalModel.selected_account.id : null
      if (!this.isUpdate && name === 'step2' && selectedAccountGroup) {
        this.$refs.step3.setIdentityCode(selectedAccountGroup)
      }
      return this.$refs[name].validate()
    },
    loadAccountDetails () {
      const accountId = (Object.keys(this.$route.params).length > 0) ? (Object.prototype.hasOwnProperty.call(this.$route.params, 'account_id') ? this.$route.params.account_id : null) : null
      this.getAccountDetails(accountId)
        .then(responseData => responseData.data.data)
        .then(data => {
          this.editAccountDetails = data
          this.iAccountId = data.id
          Object.assign(this.finalModel, { account_title: data.title })
        })
        .catch(err => {
          console.error(err)
        })
    },
    async onComplete () {
      var self = this

      await this.validateStep('step3')
        .then(function (response) {
          self.isStep3Ok = response
        })
        .catch(function (error) { console.error(error) })

      if (self.isStep3Ok) {
        const initialValueTitle = this.$t(this.translationPath + 'initial_value_for_' + this.typeOfAccount)
        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/accounts' + (this.isUpdate ? '/' + this.$route.params.account_id : '')
        const sApiMethod = this.isUpdate ? 'put' : 'post'
        await axios({
          method: sApiMethod,
          url: sApiEndpoint,
          data: this.wizardData()
        })
          .then(response => response.data.data)
          .then(function (response) {
            self.$bvModal.show('successModal')
            const startingValue = !self.isUpdate && self.finalModel && Object.prototype.hasOwnProperty.call(self.finalModel, 'starting_value') && self.finalModel.starting_value ? self.finalModel.starting_value : null
            const networthAccountId = self.currentCOA && Object.prototype.hasOwnProperty.call(self.currentCOA, 'networth_account_id') && self.currentCOA.networth_account_id ? self.currentCOA.networth_account_id : null
            if (startingValue && networthAccountId) {
              const { amount, date } = startingValue
              if (amount && date) {
                const { id, type } = response
                const postData = [{
                  title: initialValueTitle,
                  date: date ? self.getFormattedDate(date) : date,
                  type: 'transfer',
                  description: null,
                  entries: [
                    {
                      // networth
                      transaction_id: 0,
                      account_id: id,
                      offset_account_id: networthAccountId,
                      dctype: type === 'asset' ? 'dr' : 'cr',
                      amount: Math.abs(amount)
                    },
                    {
                      transaction_id: 0,
                      account_id: networthAccountId,
                      offset_account_id: id,
                      dctype: type === 'asset' ? 'cr' : 'dr',
                      amount: Math.abs(amount)
                    }
                  ]
                }]
                const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/transactions/with/entries'
                axios({
                  method: 'post',
                  url: sApiEndpoint,
                  data: {
                    transactions: postData
                  }
                }).catch(err => {
                  console.error(err)
                })
              }
            }
            self.$refs.step.reset()
            if (self.$route.name === 'asset-accounts-edit') {
              self.loadAccountDetails()
            }
          })
          .catch(function (error) {
            console.error(error)
          })
      }
    },
    mergePartialModels (model) {
      // merging each step model into the final model
      this.finalModel = Object.assign({}, this.finalModel, model)
    },
    wizardData () {
      return {
        group_id: Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'id') && this.finalModel.selected_account.id ? this.finalModel.selected_account.id : null,
        title: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_title') && this.finalModel.account_title ? this.finalModel.account_title : null,
        type: Object.prototype.hasOwnProperty.call(this.finalModel, 'selected_account') && Object.prototype.hasOwnProperty.call(this.finalModel.selected_account, 'type') && this.finalModel.selected_account.type ? this.finalModel.selected_account.type : null,
        status: Object.prototype.hasOwnProperty.call(this.finalModel, 'status') && this.finalModel.status ? this.finalModel.status : null,
        description: Object.prototype.hasOwnProperty.call(this.finalModel, 'template') ? this.finalModel.template : null,
        default_active_percentage: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_percentage') ? this.finalModel.account_percentage : null,
        code: Object.prototype.hasOwnProperty.call(this.finalModel, 'account_code') ? this.finalModel.account_code : null,
        is_importable: Object.prototype.hasOwnProperty.call(this.finalModel, 'is_importable') && this.finalModel.is_importable ? this.finalModel.is_importable : 0,
        importable_type: Object.prototype.hasOwnProperty.call(this.finalModel, 'is_importable_value') && this.finalModel.is_importable_value ? this.finalModel.is_importable_value : null,
        importable_default_parser: Object.prototype.hasOwnProperty.call(this.finalModel, 'importable_default_parser') && this.finalModel.importable_default_parser ? this.finalModel.importable_default_parser : null,
        in_bucket: Object.prototype.hasOwnProperty.call(this.finalModel, 'in_bucket') && this.finalModel.in_bucket ? this.finalModel.in_bucket : null,
        global_id: Object.prototype.hasOwnProperty.call(this.finalModel, 'global_id') && this.finalModel.global_id ? this.finalModel.global_id : null,
        is_bucket_free_capital: Object.prototype.hasOwnProperty.call(this.finalModel, 'is_bucket_free_capital') && this.finalModel.is_bucket_free_capital !== null ? this.finalModel.is_bucket_free_capital : 1,
        allow_update: Object.prototype.hasOwnProperty.call(this.finalModel, 'allow_update') && this.finalModel.allow_update ? this.finalModel.allow_update : 0,
        subtype: Object.prototype.hasOwnProperty.call(this.finalModel, 'subtype') && this.finalModel.subtype ? this.finalModel.subtype : null,
        default_offset_account_id: Object.prototype.hasOwnProperty.call(this.finalModel, 'default_offset_account_id') && this.finalModel.default_offset_account_id ? this.finalModel.default_offset_account_id : null
      }
    },
    async getAccountDetails (accountId) {
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/${accountId}`)
    }
  },
  watch: {
    finalModel () {
      this.h1AccountTitle = ': "' + this.finalModel.account_title + '"'
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
@import '@/assets/scss/modal.scss';
.create-asset-wrapper-v2 {
  .vue-form-wizard {
    padding-bottom: 0;
  }
  h1 {
    color: #545b7a;
  }
  h2 {
    color: #545b7a;
  }
  h4 {
    color: #545b7a;
  }
  .FormLabels {
    color: #545b7a;
    font-weight: 600;
  }
  legend {
    color: #545b7a;
    font-weight: 600;
  }
  label {
    color: #545b7a;
    font-weight: 600;
  }
  p {
    color: #9094a8 !important;
    font-weight: 400;
    a {
      color: #545b7a;
    }
  }
}
.create-account-wizard-asset {
  padding:0;
  .accounts_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width:767.98px) {
      border-left:none;
    }
  }
  .form-group {
    .col {
      @media screen and (max-width:767.98px) {
        padding-left: 5px;
      }
    }
  }
  label {
    &.FormLabels {
      padding-top: 14px;
    }
  }
  legend {
    padding-top: 14px;
  }
}
</style>
