<template>
  <b-container class="create-account-wizard-asset create-account-wizard-asset-step-03">
    <b-row no-gutters>
      <b-col sm="12" md="5" lg="4" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5" align-self="start">
        <h2 class="text-dark">
          {{ $t(translation_path + 'title_name') }}
        </h2>
        <i18n :path="translation_path + 'intro_p1_main'" tag="p" class="text-gray">
          <b-link slot="link" href="https://forum.spirecta.se/t/fordjupad-information-om-tillgangar/62" target="_blank">{{ $t(translation_path + 'read_more_link') }}</b-link>
        </i18n>
      </b-col>
      <b-col sm="12" md="7" lg="8" class="accounts_wrapper pt-0 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5">
        <template v-if="!isUpdate">
          <div class="starting_value">
            <h4 style="border-top:0">{{ $t(translation_path + 'starting_value')}}</h4>
            <p>
              {{ $t(translation_path + 'starting_value_description')}}
            </p>
            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              :label="$t(translation_path + 'starting_value_amount')"
            >
              <currency-input
                input-class="rounded-pill"
                v-model="amount"
              />
            </b-form-group>
            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              :label="$t(translation_path + 'starting_value_date')"
            >
              <datepicker :language="selectedLang" :format="dateFormatter" v-bind:input-class="{'form-control rounded-pill': true }" v-model="date" :monday-first="startWeekByMonday" :clear-button="true" clear-button-icon="fa fa-times"></datepicker>
              <template slot="description">{{ $t(translation_path + 'starting_value_date_description') }}</template>
            </b-form-group>
          </div>
        </template>
        <!--Advanced Toggle-->
        <b-form-group
          :label-class="'pr-4 text-black' + (currentLang === 'sv' ? ' pt-2 ' : ' pt-0 ')"
          label-cols-lg="4"
          label-align-lg="right"
          :label="$t(translation_path + 'toggle_advanced')"
        >
          <b-form-checkbox v-model="showOptions" name="toggle-button" size="lg" v-bind:class="currentLang === 'sv' ? 'pt-1' : 'pt-2'" switch>
          </b-form-checkbox>
        </b-form-group>
        <!--status-->
        <template v-if="showOptions === true">

          <h4>{{ $t(translation_path + 'import_account_history') }}</h4>
          <p>{{ $t(translation_path + 'import_account_history_description') }}</p>

          <b-form-group
            :label-class="$i18n.locale === 'sv' ? 'pt-1 pr-4 text-black' : 'pt-3 pr-4 text-black'"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="is_importable"
            :label="$t(translation_path + 'is_importable')"
            :state="importableState"
            :invalid-feedback="importableStateFeedback"
          >
            <b-form-select id="is_importable" v-model="is_importable" :options="importOptions" :state="importableState" class="rounded-pill"></b-form-select>
          </b-form-group>
          <template v-if="is_importable === 1">
            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="import_type"
              :state="importableValueState"
              :label="$t(translation_path + 'import_type')"
              :invalid-feedback="invalidImportableValueFeedback"
            >
              <b-form-select id="import_type" v-model="importableValue" :options="importableOptions" :state="importableValueState" class="rounded-pill"></b-form-select>
            </b-form-group>
            <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="default_parser"
              :label="$t(translation_path + 'default_parser')"
            >
              <custom-select
                rounded
                v-model="importable_default_parser"
                :options="parserOptions"
                label="text"
                valueKey="value"
                :placeholder="$t(translation_path + 'errors.is_importable_required')"
              />
            </b-form-group>
          </template>

          <template v-if="is_importable === 1">
            <h4>{{ $t(translation_path + 'tink_title') }}</h4>
            <p>{{ $t(translation_path + 'tink_description') }}</p>

            <b-form-group
              :label-class="$i18n.locale === 'sv' ? 'pt-1 pr-4 text-black' : 'pt-3 pr-4 text-black'"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="auto_import"
              :label="$t(translation_path + 'auto_import')"
            >
              <b-form-select id="autoImport" v-model="tink_auto_import" :options="autoImportOptions" class="rounded-pill"></b-form-select>
            </b-form-group>

            <template v-if="tink_auto_import">
              <b-form-group
                :label-class="$i18n.locale === 'sv' ? 'pt-1 pr-4 text-black' : 'pt-3 pr-4 text-black'"
                label-cols-lg="4"
                label-align-lg="right"
                label-for="input_provider"
                :label="$t(translation_path + 'input_provider')"
                :invalid-feedback="invalidTinkProviderFeedback"
              >
                <b-form-select id="input_provider" v-model="tink_input_provider" :options="tinkProviderOptions" :state="tinkProviderState" class="rounded-pill" :disabled="!tink_auto_import" @input="validateTinkFields"></b-form-select>
              </b-form-group>

              <b-form-group
                :label-class="$i18n.locale === 'sv' ? 'pt-1 pr-4 text-black' : 'pt-3 pr-4 text-black'"
                label-cols-lg="4"
                label-align-lg="right"
                label-for="tink_account"
                :label="$t(translation_path + 'tink_account')"
                xinvalid-feedback="invalidTinkAccountFeedback"
              >
                <b-form-select id="tink_account" v-model="tink_id" :options="tinkAccountOptions" class="rounded-pill" xstate="tinkAccountState" :disabled="!tink_auto_import" @input="validateTinkFields"></b-form-select>
              </b-form-group>
            </template>
          </template>

          <h4>{{ $t(translation_path + 'subtype_title') }}</h4>
          <p>{{ $t(translation_path + 'subtype_description') }}</p>

          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="subtype"
            :label="$t(translation_path + 'subtype')"
          >
            <b-form-select id="subtype" v-model="subtype" :options="subtypeOptions" class="rounded-pill"></b-form-select>
          </b-form-group>

          <h4>{{ $t(translation_path + 'expected_annual_return_title') }}</h4>
          <p>{{ $t(translation_path + 'expected_annual_return_description') }}</p>

          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="expected_annual_return"
            :label="$t(translation_path + 'expected_annual_return')"
          >
            <percentage-input
              input-class="rounded-pill"
              :value="expected_annual_return"
              :precision="2"
              @input="onExpectedAnnualReturnInput" />
          </b-form-group>

          <h4>{{ $t(translation_path + 'compare_yourself_heading') }}</h4>
          <p>{{ $t(translation_path + 'compare_yourself_description') }}</p>
          <b-form-group
            label-class="pt-0 pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
          >
            <template slot="label">
              <i18n :path="translation_path + (currentUser.strict_accounting_mode ? 'global_account': 'global_category')">
                <b-link slot="help_link" href="https://forum.spirecta.se/t/fordjupad-information-om-tillgangar/62#j%C3%A4mf%C3%B6r-dig-med-andra" target="_blank">(?)</b-link>
              </i18n>
            </template>
            <custom-select rounded
              v-model="global_id"
              :options="globalAccountsOptions"
              groupingValues="accounts"
              groupingLabel="label"
              :placeholder="currentUser.strict_accounting_mode ? $t(translation_path + 'select_global_account') : $t(translation_path + 'select_global_category')"
              :search-placeholder="$t('common.type_to_filter')"
              :noResultText="$t('common.no_option_found')"
              :loading="loadingGlobalAccounts"
              :sortGroupLabel="false"
              :enableDiselect="true"
              label="label"
            />
          </b-form-group>

          <h4>{{ $t(translation_path + 'four_buckets_principle') }}</h4>
          <p>{{ $t(translation_path + 'four_buckets_principle_description') }}</p>
          <b-form-group
            :label="$t(translation_path + 'in_bucket_label')"
            label-class="FormLabels pr-4 pt-1 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="in_bucket"
            :state="inbucketState"
            :invalid-feedback="inbucketStateFeedback"
          >
            <b-form-select id="in_bucket" v-model="in_bucket" :options="inbucketOptions" :state="inbucketState" class="rounded-pill"></b-form-select>
          </b-form-group>

          <template v-if="in_bucket">
            <b-form-group
              :label="$t(translation_path + 'is_bucket_free_capital')"
              label-class="FormLabels pr-4 pt-3 text-black"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="is_bucket_free_capital"
              :state="isBucketFreeCapitalState"
              :invalid-feedback="isBucketFreeCapitalStateFeedback"
            >
              <b-form-select id="in_bucket_free_capital" v-model="is_bucket_free_capital" :options="isBucketFreeCapitalOptions" :state="isBucketFreeCapitalState" class="rounded-pill"></b-form-select>
            </b-form-group>
          </template>

          <h4>{{ $t(translation_path + 'other_options') }}</h4>
          <p>{{ $t(translation_path + 'other_options_description') }}</p>

          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="status"
            :state="statusState"
            :invalid-feedback="invalidStatusFeedback"
            :label="$t(translation_path + 'status_label')"
          >
            <b-form-select id="status" v-model="status" :options="aStatusOptions" :state="statusState" @input="optionChangeHandler" class="rounded-pill"></b-form-select>
          </b-form-group>

          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="allow_update"
            :state="allowUpdateState"
            :invalid-feedback="allowUpdateStateFeedback"
          >
            <template slot="label">
              <i18n :path="translation_path + 'allow_update'">
                <b-link slot="help_link" href="#" v-b-toggle.sidebar-allow-update>(?)</b-link>
              </i18n>
            </template>
            <b-sidebar id="sidebar-allow-update" right shadow backdrop>
              <div class="px-3 py-2">
                {{ $t(translation_path + 'sidebar.allow-update' ) }}
              </div>
            </b-sidebar>
            <b-form-select id="allow_update" v-model="allow_update" :options="allowUpdateOptions" :state="allowUpdateState" class="rounded-pill"></b-form-select>
          </b-form-group>
          <!--offset account-->
          <template v-if="allow_update === 1">
            <b-form-group
              label-class="FormLabels pr-4 text-black pt-0"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="offset_account"
              :state="default_offset_account_id !== null ? true : null"
            >
              <template slot="label">
                <i18n :path="translation_path + 'offset_account'">
                  <b-link slot="help_link" href="#" v-b-toggle.sidebar-offset-account>(?)</b-link>
                </i18n>
              </template>
              <b-sidebar id="sidebar-offset-account" right shadow backdrop>
                <div class="px-3 py-2">
                  {{ $t(translation_path + 'sidebar.offset-account' ) }}
                </div>
              </b-sidebar>
              <custom-select rounded
                v-model="default_offset_account"
                :options="offsetAccOptions"
                groupingValues="accounts"
                groupingLabel="label"
                :placeholder="$t('common.please_select_an_option')"
                :search-placeholder="$t('common.type_to_filter')"
                :noResultText="$t('common.no_option_found')"
                :loading="loadingOffsetOptions"
                :sortGroupLabel="false"
                :enableDiselect="true"
                label="label"
              />
            </b-form-group>
          </template>
          <b-form-group
            label-class="FormLabels pr-4 text-black"
            label-cols-lg="4"
            label-align-lg="right"
            :invalid-feedback="invalidAccountCodeFeedback"
            :state="!$v.account_code.$error"
            label-for="identification_code"
          >
            <template slot="label">
              <i18n :path="translation_path + 'code_label'">
                <b-link slot="help_link" href="#" v-b-toggle.sidebar-help-code>(?)</b-link>
              </i18n>
            </template>
            <b-sidebar id="sidebar-help-code" right shadow backdrop>
              <div class="px-3 py-2">
                {{ $t(translation_path + 'sidebar.help-code' ) }}
              </div>
            </b-sidebar>
            <template slot="description">{{ $t(translation_path + 'code_help_text') }} {{ $t(translation_path + 'code_help_text_recommendation_'+typeOfAccount ) }}</template>
            <b-form-input id="identification_code" :placeholder="$t(translation_path + 'code_placeholder')" v-model="account_code" :state="$v.account_code.$dirty ? !$v.account_code.$error : null" @input="$v.account_code.$touch()" class="rounded-pill"></b-form-input>
          </b-form-group>
          <b-form-group
              label-class="FormLabels pr-4 text-black"
              label-for="description"
              label-cols-lg="4"
              label-align-lg="right"
          >
            <template slot="label">{{ $t(translation_path_common + 'description_label') }}</template>
            <template slot="description">{{ $t(translation_path_common + 'description_help_text') }}</template>
            <b-form-textarea v-model="description"></b-form-textarea>
          </b-form-group>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import CurrencyInput from '@/components/common/CurrencyInput'
import Datepicker from 'vuejs-datepicker'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import { helpers, required, requiredUnless } from 'vuelidate/lib/validators'
import axios from 'axios'
import EventBus from '@/event-bus'
import GlobalsMixins from '@/mixins/globals'
import AssetLiabilitiesSubtypes from '@/mixins/assetLiabilitiesSubtypes'
import CustomSelect from '@/components/common/CustomSelect'
import _ from 'lodash'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import PercentageInput from '@/components/common/PercentageInput'

const customAccountCodeRule = helpers.regex('customAccountCodeRule', /^\d{4,6}$/)

const isCodeUnique = getter => function () {
  return this.is_code_unique
}
const isTinkProviderOk = getter => function () {
  return this.is_tink_provider_ok
}
/* const isTinkAccountOk = getter => function () {
  return this.is_tink_account_ok
} */

export default {
  name: 'AssetAccountWizardStep03',
  components: { Datepicker, CustomSelect, CurrencyInput, PercentageInput },
  mixins: [GlobalsMixins, AssetLiabilitiesSubtypes],
  props: ['initialData', 'typeOfAccount', 'finalModel', 'isUpdate'],
  data () {
    return {
      translation_path: 'assets_liabilities.create_account_wizard.assets.step3.',
      translation_path_subtypes: 'common.asset_liabilities.subtypes.',
      translation_path_common: 'assets_liabilities.create_account_wizard.common.step3.',
      status: 'active',
      disabledDates: {
        to: new Date()
      },
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      importableValue: null,
      is_importable: 0,
      in_bucket: null,
      is_bucket_free_capital: 1,
      allow_update: 1,
      offsetAccounts: [],
      default_offset_account_id: null,
      default_offset_account: null,
      loadingOffsetOptions: true,
      networthAccountId: null,
      importable_default_parser: null,
      validated: false,
      submitted: false,
      globalAccounts: [],
      global_id: null,
      showOptions: false,
      account_id: null,
      account_code: null,
      subtype: null,
      amount: 0,
      description: null,
      date: new Date().toISOString().slice(0, 10),
      is_code_unique: true,
      is_tink_provider_ok: true,
      is_tink_account_ok: true,
      loadingGlobalAccounts: false,
      parsers: [],
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      tink_input_provider: null,
      tinkProviders: [],
      tink_id: null,
      tink_auto_import: 0,
      expected_annual_return: 0
    }
  },
  validations: {
    account_code: {
      customAccountCodeRule,
      codeUnique: isCodeUnique()
    },
    importableValue: {
      required: requiredUnless('importValueRequired')
    },
    status: {
      required
    },
    tink_input_provider: { isTinkProviderOk: isTinkProviderOk() },
    // tink_id: { isTinkAccountOk: isTinkAccountOk() },
    form: ['account_code', 'status', 'importableValue', 'tink_input_provider'/* , 'tink_id' */]
  },
  mounted () {
    this.loadTinkProviders()
    this.fetchParser()
    if (this.currentUser && Object.prototype.hasOwnProperty.call(this.currentUser, 'email') && this.currentUser.email) {
      this.loadingGlobalAccounts = true
      this.fetchGlobalAccounts(this.typeOfAccount, this.currentUser.default_lang)
        .then(data => {
          const AllAccountsGlobals = Object.prototype.hasOwnProperty.call(data, 'accounts') ? data.accounts : []
          this.globalAccounts = AllAccountsGlobals
          this.setGlobalAccount()
          this.loadingGlobalAccounts = false
        })
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    autoImportOptions () {
      return [
        { value: 0, text: this.$t('common.off') },
        { value: 1, text: this.$t('common.on') }
      ]
    },
    tinkProviderOptions () {
      const opts = []
      this.tinkProviders.map(item => {
        opts.push({ text: this.$t(item.translation_string_id), value: item.provider_name })
      })

      return opts.sort((a, b) => a.text < b.text ? -1 : 1)
    },
    tinkAccountOptions () {
      const opts = []
      this.tinkProviders.map(provider => {
        if (provider.provider_name === this.tink_input_provider) {
          provider.tink_accounts.map(account => {
            opts.push({
              text: (account.connection ? account.connection.name : this.$t(this.translationPath + 'no_connection_prefix')) + ' - ' + account.name + ' - ' + account.number,
              value: account.tink_account_id,
              provider: provider.provider_name
            })
          })
        }
      })
      return opts.sort((a, b) => a.text < b.text ? -1 : 1)
    },
    offsetOptions () {
      const accountsData = this.offsetAccounts.map(item => Object.assign({}, item, { type: item.type }))
      const typesAccount = [...new Set(this.offsetAccounts.map(item => item.type))]
      const allAccounts = typesAccount.map(item => {
        const newItem = { name: item }
        const groupitems = this.isUpdate ? accountsData.filter(groupItems => groupItems.type === item).filter(grpItems => parseInt(grpItems.id) !== parseInt(this.account_id)) : accountsData.filter(groupItems => groupItems.type === item)
        return Object.assign(newItem, { accounts: groupitems })
      })
      const income = typesAccount.indexOf('income') > -1 ? allAccounts.filter(item => item.name === 'income') : []
      const expense = typesAccount.indexOf('expense') > -1 ? allAccounts.filter(item => item.name === 'expense') : []
      const assets = typesAccount.indexOf('asset') > -1 ? allAccounts.filter(item => item.name === 'asset') : []
      const liability = typesAccount.indexOf('liability') > -1 ? allAccounts.filter(item => item.name === 'liability') : []
      return [...income, ...expense, ...assets, ...liability].filter(GrpItems => GrpItems.accounts.length > 0)
    },
    offsetAccOptions () {
      // console.log('this.offsetOptions :::', this.offsetOptions)
      return this.offsetOptions.map(item => {
        const accountsValues = item.accounts.map(AccItem => {
          return Object.assign(AccItem, {
            label: this.currentCOA.prefix_account_title_with_code ? AccItem.title_with_code : AccItem.title
          })
        }).filter((accEl) => accEl.is_capital_account === 1 || this.networthAccountIdValue === accEl.id)

        const groupType = this.$te('common.' + item.name) ? this.$t('common.' + item.name) : item.name

        return Object.assign(item, {
          label: groupType,
          accounts: accountsValues
        })
      }).filter((el) => el.accounts.length > 0)
    },
    globalAccountsOptions () {
      const accountsOptions = this.globalAccounts.map(item => {
        const accountsValues = item.accounts.map(AccItem => {
          return Object.assign(AccItem, {
            label: this.currentCOA.prefix_account_title_with_code ? AccItem.title_with_code : AccItem.title
          })
        })
        const groupType = this.$te('common.' + item.type) ? this.$t('common.' + item.type) : item.type
        const label = groupType + ' / ' + item.title
        return Object.assign(item, {
          label,
          accounts: accountsValues
        })
      })
      return accountsOptions
    },
    inbucketOptions () {
      return [
        { value: null, text: this.$t(this.translation_path + 'errors.is_importable_required') },
        { value: 'conservative', text: this.$t(this.translation_path + 'in_bucket.conservative') },
        { value: 'moderate', text: this.$t(this.translation_path + 'in_bucket.moderate') },
        { value: 'aggressive', text: this.$t(this.translation_path + 'in_bucket.aggressive') },
        { value: 'speculation', text: this.$t(this.translation_path + 'in_bucket.speculation') }
      ]
    },
    isBucketFreeCapitalOptions () {
      return [
        { value: 1, text: this.$t(this.translation_path + 'free_capital') },
        { value: 0, text: this.$t(this.translation_path + 'tied_capital') }
      ]
    },
    allowUpdateOptions () {
      return [
        { value: 0, text: this.$t('common.no') },
        { value: 1, text: this.$t('common.yes') }
      ]
    },
    subtypeOptions () {
      return this.getAssetSubtypesArray()
    },
    currentLang () {
      return this.$i18n.locale
    },
    inbucketStateFeedback () {
      return ''
    },
    isBucketFreeCapitalStateFeedback () {
      return ''
    },
    invalidTinkProviderFeedback () {
      if (this.$v.tink_input_provider.isTinkProviderOk === false) { return this.$t(this.translation_path + 'errors.tink_provider_required') }
      return ''
    },
    /* invalidTinkAccountFeedback () {
      if (this.$v.tink_id.isTinkAccountOk === false) { return this.$t(this.translation_path + 'errors.tink_account_required') }
      return ''
    }, */
    inbucketState () {
      return (this.submitted) ? true : null
    },
    tinkProviderState () {
      if (!this.submitted) {
        return null
      }
      return this.is_tink_provider_ok
    },
    tinkAccountState () {
      if (!this.submitted) {
        return null
      }
      return this.is_tink_account_ok
    },
    isBucketFreeCapitalState () {
      return (this.submitted) ? true : null
    },
    allowUpdateStateFeedback () {
      return ''
    },
    allowUpdateState () {
      return (this.submitted) ? true : null
    },
    invalidAccountCodeFeedback () {
      if (this.$v.account_code.customAccountCodeRule === false) { return this.$i18n.t(this.translation_path + 'errors.code_invalid') }
      if (this.$v.account_code.codeUnique === false) { return this.$i18n.t(this.translation_path + 'errors.code_already_taken') }
      return ''
    },
    defaultParserOptions () {
      return [
        { text: this.$t(this.translation_path + 'default_parser_select.auto'), value: 'auto' }
      ]
    },
    importOptions () {
      return [
        { text: this.$t(this.translation_path + 'is_importable_select.yes'), value: 1 },
        { text: this.$t(this.translation_path + 'is_importable_select.no'), value: 0 }
      ]
    },
    importableOptions () {
      return [
        { text: this.$t(this.translation_path + 'is_importable_select_value.debit_card'), value: 'debit_card' },
        { text: this.$t(this.translation_path + 'is_importable_select_value.bank_account'), value: 'bank_account' },
        { text: this.$t(this.translation_path + 'is_importable_select_value.asset'), value: 'asset' },
        { text: this.$t(this.translation_path + 'is_importable_select_value.other'), value: 'other' }
      ]
    },
    intro_p2_path () {
      return this.translation_path + (this.currentUser.strict_accounting_mode ? 'intro_p2_account' : 'intro_p2_category')
    },
    aStatusOptions () {
      return [
        { text: this.$t(this.translation_path + 'active'), value: 'active' },
        { text: this.$t(this.translation_path + 'suspended'), value: 'suspended' }
      ]
    },
    statusState () {
      return this.$v.status.$dirty ? !this.$v.status.$error : null
    },
    invalidStatusFeedback () {
      if (this.$v.status.$errors) {
        return this.$t(this.translation_path + 'errors.status_required')
      } else {
        return ''
      }
    },
    importValueRequired () {
      return this.is_importable === 1 ? this.importableValue : true
    },
    importableValueState () {
      return this.$v.importableValue.$dirty ? !this.$v.importableValue.$error : null
    },
    invalidImportableValueFeedback () {
      if (this.$v.importableValue.$error) {
        return this.$i18n.t(this.translation_path + 'errors.importable_required')
      }
      return ''
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    importableState () {
      const validImortableValues = this.importOptions.map(item => item.value)
      if (this.submitted && this.is_importable === null) {
        return false
      } else if (this.submitted && validImortableValues.indexOf(this.is_importable) === -1) {
        return false
      } else {
        return (this.submitted) ? true : null
      }
    },
    importableStateFeedback () {
      const validImortableValues = this.importOptions.map(item => item.value)
      if (this.submitted && this.is_importable === null) {
        return this.$i18n.t(this.translation_path + 'errors.is_importable_required')
      } else if (this.submitted && validImortableValues.indexOf(this.is_importable) === -1) {
        return this.$i18n.t(this.translation_path + 'errors.is_importable_valid')
      } else {
        return ''
      }
    },
    valid () {
      return (this.importableState !== false && this.importableValueState !== false) && this.$v.form.$invalid !== true
    },
    parserOptions () {
      const parserMap = this.parsers.map(item => {
        return Object.assign({}, { text: this.$te('common.' + item.parser_nice_name) ? this.$t('common.' + item.parser_nice_name) : item.parser_nice_name, value: item.class_name })
      })
      return [...this.defaultParserOptions, ...parserMap].sort((a, b) => a.text > b.text ? 1 : -1)
    },
    networthAccountIdValue () {
      return this.currentCOA && Object.prototype.hasOwnProperty.call(this.currentCOA, 'networth_account_id') && this.currentCOA.networth_account_id ? this.currentCOA.networth_account_id : null
    }
  },
  methods: {
    fetchAccount (fetchType) {
      this.loadingOffsetOptions = true
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?limit=1000&account_type=${fetchType}`)
        .then(response => response.data.data)
        .then(response => {
          this.offsetAccounts = response
          if (!this.default_offset_account_id && this.networthAccountId) {
            this.default_offset_account_id = this.networthAccountId
          }
        })
        .catch(err => {
          console.warn(err)
        })
        .finally(() => {
          this.loadingOffsetOptions = false
        })
    },
    loadTinkProviders () {
      if (!this.currentCOA.locale) {
        return true
      }

      const useTestProviders = Number(process.env.VUE_APP_USE_TINK_TEST_PROVIDERS === '1')
      axios.get(`${process.env.VUE_APP_ROOT_API}/tink/providers?is_test=${useTestProviders}`)
        .then(response => {
          this.tinkProviders = response.data.data
          this.tinkProviders.map(tinkProvider => {
            tinkProvider.tink_accounts.map(account => {
              if (account.connection) {
                const tmp = account.connection.name.match(/#.+#/)
                if (tmp !== null) {
                  account.connection.name = account.connection.name.replace(/#.+#/, this.$t(tmp[0].replaceAll('#', '')))
                }
              }
            })
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    async setIdentityCode (groupId) {
      await this.fetchIdentityCode(groupId)
        .then(responseData => {
          this.account_code = responseData && Object.keys(responseData).indexOf('suggested_code') > -1 ? responseData.suggested_code : null
        })
    },
    fetchParser () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/bankstatements/parsers`)
        .then(response => {
          this.parsers = response.data.data
          this.loadDefaultParser()
        })
        .catch(error => console.error(error))
    },
    loadDefaultParser () {
      const selectedParser = Object.prototype.hasOwnProperty.call(this.initialData, 'importable_default_parser') && this.initialData.importable_default_parser ? this.initialData.importable_default_parser : null
      const selectParserOption = this.parserOptions.filter(item => item.value === selectedParser)
      this.importable_default_parser = selectParserOption.length > 0 ? selectParserOption[0] : null
    },
    setGlobalAccount () {
      const accounts = this.globalAccounts.map(item => item.accounts)
      const deepEqualAccounts = _.flattenDeep(accounts).filter(item => item.id === this.global_id).map(item => {
        return Object.assign(item, {
          label: this.currentCOA.prefix_account_title_with_code ? item.title_with_code : item.title
        })
      })
      this.global_id = deepEqualAccounts.length > 0 ? deepEqualAccounts[0] : null
    },
    async validate () {
      this.$v.form.$touch()
      this.validateTinkFields()

      this.submitted = true
      let isValid = this.valid
      var self = this

      if (this.allow_update === 0) {
        this.default_offset_account_id = null
      }

      if (!this.valid && !this.showOptions) {
        this.showOptions = true
      }

      const postData = {
        only_validate: true,
        title: this.finalModel.account_title,
        default_active_percentage: 0,
        type: this.typeOfAccount,
        status: this.status,
        code: this.account_code,
        description: this.description,
        global_id: this.global_id ? this.global_id.id : null,
        is_importable: this.is_importable,
        is_importable_value: this.importableValue,
        in_bucket: this.in_bucket,
        importable_default_parser: this.importable_default_parser ? this.importable_default_parser.value : null,
        subtype: this.subtype,
        default_offset_account_id: this.default_offset_account_id,
        tink_auto_import: this.tink_auto_import,
        id: this.account_id
      }

      if (this.tink_auto_import) {
        postData.tink_input_provider = this.tink_input_provider
        postData.tink_id = this.tink_id
      }

      const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/accounts' + (this.isUpdate ? '/' + this.account_id : '')
      const sApiMethod = this.isUpdate ? 'put' : 'post'
      // Validate that code is unique on create
      if (isValid) {
        const responseValidate = await axios({
          method: sApiMethod,
          url: sApiEndpoint,
          data: postData
        })
          .then(responseData => {
            let data = {
              status: this.status,
              account_code: this.account_code,
              description: this.description,
              is_importable: this.is_importable,
              is_importable_value: this.importableValue,
              importable_default_parser: this.importable_default_parser ? this.importable_default_parser.value : null,
              global_id: this.global_id ? this.global_id.id : null,
              in_bucket: this.in_bucket,
              is_bucket_free_capital: this.is_bucket_free_capital,
              allow_update: this.allow_update,
              subtype: this.subtype,
              default_offset_account_id: this.default_offset_account_id,
              tink_auto_import: this.tink_auto_import,
              expected_annual_return: this.expected_annual_return
            }
            if (this.tink_auto_import) {
              data.tink_input_provider = this.tink_input_provider
              data.tink_id = this.tink_id
            }
            const networthAccountId = this.networthAccountIdValue
            if (networthAccountId && !this.isUpdate) {
              const { amount, date } = this
              data = Object.assign({}, data, {
                starting_value: {
                  amount,
                  date
                }
              })
            }
            this.$emit('on-validate', data)
            return isValid
          })
          .catch(function (error) {
            isValid = false

            if (error.response.status === 422 && typeof (error.response.data.error.data.code) !== 'undefined') {
              self.is_code_unique = false
            }

            if (error.response.status === 422 && typeof (error.response.data.error.data.title) !== 'undefined') {
              EventBus.$emit('make-toast', {
                text: this.$t(this.translation_path + 'errors.title_already'),
                status: 'danger'
              })
            }
            if (!self.is_code_unique && !self.showOptions) {
              self.showOptions = true
            }
            return isValid
          })
        return responseValidate
      } else {
        return isValid
      }
    },
    dateFormatter (date) {
      return this.currentCOA.locale === 'sv-SE' ? this.$moment(date).format('YYYY-MM-DD') : this.$moment(date).format('MM-DD-YYYY')
    },
    optionChangeHandler () {
      this.submitted = false
    },
    setInitialDate (initialData) {
      this.account_id = initialData.id
    },
    validateTinkFields () {
      this.is_tink_provider_ok = true
      this.is_tink_account_ok = true
      if (this.tink_auto_import) {
        if (!this.tink_input_provider) {
          this.is_tink_provider_ok = false
        }
        if (!this.tink_id) {
          this.is_tink_account_ok = false
        }
        if (!this.tinkAccountOptions.length) {
          this.is_tink_account_ok = false
        }
        let isPresent = false
        this.tinkAccountOptions.map(item => {
          if (item.value === this.tink_id) {
            isPresent = true
          }
        })
        if (!isPresent) {
          this.is_tink_account_ok = false
        }
      }
    },
    onExpectedAnnualReturnInput (newVal) {
      this.expected_annual_return = newVal
    }
  },
  watch: {
    currentCOA: {
      handler: function () {
        this.loadTinkProviders()
        if (this.currentCOA && Object.prototype.hasOwnProperty.call(this.currentCOA, 'networth_account_id')) {
          this.networthAccountId = this.currentCOA.networth_account_id
          if (this.allow_update) {
            this.default_offset_account_id = this.currentCOA.networth_account_id
          }
        }
      },
      immediate: true
    },
    initialData: {
      handler: function (val) {
        if (val && Object.keys(val).length > 0) {
          this.setInitialDate(val)
          this.status = val.status
          this.account_code = Object.prototype.hasOwnProperty.call(val, 'code') && val.code ? val.code : null
          this.description = Object.prototype.hasOwnProperty.call(val, 'description') && val.description ? val.description : null
          this.is_importable = Object.prototype.hasOwnProperty.call(val, 'is_importable') && val.is_importable ? val.is_importable : 0
          this.importableValue = Object.prototype.hasOwnProperty.call(val, 'importable_type') && val.importable_type ? val.importable_type : null
          // this.importable_default_parser = Object.prototype.hasOwnProperty.call(val, 'importable_default_parser') && val.importable_default_parser ? val.importable_default_parser : null
          this.global_id = Object.prototype.hasOwnProperty.call(val, 'global_id') && val.global_id ? val.global_id : null
          this.in_bucket = Object.prototype.hasOwnProperty.call(val, 'in_bucket') && val.in_bucket ? val.in_bucket : null
          this.is_bucket_free_capital = Object.prototype.hasOwnProperty.call(val, 'is_bucket_free_capital') && val.is_bucket_free_capital === 0 ? val.is_bucket_free_capital : 1
          this.allow_update = Object.prototype.hasOwnProperty.call(val, 'allow_update') && val.allow_update ? val.allow_update : 0
          this.subtype = Object.prototype.hasOwnProperty.call(val, 'subtype') && val.subtype ? val.subtype : null
          this.default_offset_account_id = Object.prototype.hasOwnProperty.call(val, 'default_offset_account_id') && val.default_offset_account_id ? val.default_offset_account_id : null
          this.tink_input_provider = Object.prototype.hasOwnProperty.call(val, 'tink_input_provider') && val.tink_input_provider ? val.tink_input_provider : null
          this.tink_id = Object.prototype.hasOwnProperty.call(val, 'tink_id') && val.tink_id ? val.tink_id : 0
          this.tink_auto_import = Object.prototype.hasOwnProperty.call(val, 'tink_auto_import') && val.tink_auto_import ? val.tink_auto_import : 0
          this.expected_annual_return = Object.prototype.hasOwnProperty.call(val, 'expected_annual_return') && val.expected_annual_return ? Number(val.expected_annual_return) : 0
        }
      },
      immediate: true,
      deep: true
    },
    account_code () {
      this.is_code_unique = true
    },
    allow_update: {
      handler () {
        if (this.allow_update) {
          this.fetchAccount('income,liability')
        } else {
          this.default_offset_account_id = null
        }
      },
      immediate: true
    },
    is_importable: function (newVal) {
      if (newVal === 0) {
        this.importableValue = null
        this.importable_default_parser = null
      }
    },
    default_offset_account: function (val) {
      if (val) {
        this.default_offset_account_id = val.id
      }
    },
    offsetAccounts: function (val) {
      if (val && this.default_offset_account_id) {
        const selectedAccount = val.filter((el) => el.id === this.default_offset_account_id)
        if (selectedAccount.length > 0) {
          this.default_offset_account = Object.assign({}, selectedAccount[0], { label: this.currentCOA.prefix_account_title_with_code ? selectedAccount[0].title_with_code : selectedAccount[0].title })
        }
      }
    },
    default_offset_account_id: function (val) {
      if (val && this.offsetAccount) {
        const selectedAccount = this.offsetAccount.filter((el) => el.id === val)
        if (selectedAccount.length > 0) {
          this.default_offset_account = Object.assign({}, selectedAccount[0], { label: this.currentCOA.prefix_account_title_with_code ? selectedAccount[0].title_with_code : selectedAccount[0].title })
        }
      }
    },
    currentUser: function (newVal) {
      if (newVal && Object.prototype.hasOwnProperty.call(newVal, 'email') && newVal.email) {
        this.loadingGlobalAccounts = true
        this.fetchGlobalAccounts(this.typeOfAccount, this.currentUser.default_lang)
          .then(data => {
            const AllAccountsGlobals = Object.prototype.hasOwnProperty.call(data, 'accounts') ? data.accounts : []
            this.globalAccounts = AllAccountsGlobals
            this.setGlobalAccount()
            this.loadingGlobalAccounts = false
          })
      }
    },
    tink_auto_import (newVal) {
      this.validateTinkFields()
    }
  },
  filters: {
    displayAccountTitle
  }
}
</script>

<style lang="scss">
.create-account-wizard-asset-step-03 {
  .form-control[readonly]{
    background-color:#fff;
  }
  .FormLabels {
    padding-top: 16px;
  }
  .vdp-datepicker__clear-button {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  .starting_value {
    padding-bottom: 5px;
    border-bottom: 1px dashed gray;
    margin-bottom: 15px;
    h4 {
      margin-top: 0;
      padding-top: 10px;
    }
  }
  h4{margin-top:25px;padding-top:25px;border-top:1px dashed gray}
}
</style>
